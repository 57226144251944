import { useLocation, WindowLocation } from "@reach/router"
import { graphql, PageProps, useStaticQuery } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Statements from "../components/Statements"

type TitleProps = {
  location: WindowLocation
  site?: {
    siteMetadata: {
      exampleUrl: string
      logo: string
    }
  }
}

type DataProps = {
  site?: {
    buildTime: string
  }
}

type IndexSiteProps = { location: WindowLocation }

const StatementPage: React.FC<PageProps<DataProps>> = ({ path }) => {
  const data = useStaticQuery<TitleProps>(graphql`
    query {
      site {
        siteMetadata {
          contact {
            api_url
            description
            mail
            phone
            address
          }
        }
      }
    }
  `)

  const location = useLocation()

  return (
    <>
      <Seo title="Reiki Grad Wien Kunden Aussagen" />
      <Layout location={location}>
        <Statements />
      </Layout>
    </>
  )
}

export default StatementPage
